import { useRef, useState } from 'react';

import Map, {
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  MapRef,
  NavigationControl,
  ScaleControl,
} from 'react-map-gl';

import CoordinatesBar from './CoordinatesBar/CoordinatesBar';

import MapStyleLayer from './MapStyleLayer/MapStyleLayer';
import classes from './MapSW.module.scss';
import { Maps } from '../../tsTypes/enums';

interface IProps {
  children?: React.ReactNode;
  hideCoordinatesBar?: boolean;
  height?: number;
  mapId?: string;
  initMap?: Maps;
}

/**
 * DRAW POLYGON ON MAP | React Map-Gl
 * https://visgl.github.io/react-map-gl/examples/draw-polygon
 * 
 * https://visgl.github.io/react-map-gl/
 * Directions API of MAP BOX
 * https://docs.mapbox.com/api/navigation/directions/
 https://docs.mapbox.com/playground/directions/

 current version of directions API v5
 {longitude,latitude;longitude,latitude}
 https://api.mapbox.com/directions/v5/mapbox/[profile]/[semi-colon separated coordinates]?access_token=[mapbox access token]


 //Sample request
 https://api.mapbox.com/directions/v5/mapbox/cycling/-84.518641,39.134270;-84.512023,39.102779?geometries=geojson

 */

const MapSW = ({
  children,
  hideCoordinatesBar,
  height,
  mapId,
  initMap,
}: IProps) => {
  const [viewState, setViewState] = useState({
    longitude: 34.5718694118,
    latitude: 32.2566671079,
    zoom: 9.05,
  });
  const mapRef = useRef<MapRef | null>(null);

  const mapInitState = initMap || Maps.navigationMap;

  const [mapStyle, setMapStyle] = useState(mapInitState);

  const changeMapHandler = (map: Maps) => {
    setMapStyle(map);
  };

  return (
    <div className={classes.MapContentSection}>
      {!hideCoordinatesBar && (
        <CoordinatesBar
          latitude={viewState.latitude}
          longitude={viewState.longitude}
          zoom={viewState.zoom}
        />
      )}
      <MapStyleLayer onMapSelect={changeMapHandler} selected={mapInitState} />
      <Map
        initialViewState={{ ...viewState }}
        mapStyle={mapStyle}
        mapboxAccessToken='pk.eyJ1IjoiYXl0YWNnMjYiLCJhIjoiY2xobThyNmlhMTl2YjNxbnV2YXBreW04eCJ9.sVAdyc54G2c7DPtumtLTDg'
        attributionControl={false}
        onMove={(evt) => setViewState(evt.viewState)}
        style={{
          height: `${height ? `${height}px` : '650px'}`,
          width: '86dvw',
          maxWidth: 'inherit',
          margin: '0 auto',
        }}
        id={`${mapId ? mapId : 'CyprusMap'}`}
        maxBounds={[
          [32.2566671079, 34.4718694118],
          [34.1168808123, 35.2731247015],
        ]}
        maxZoom={18.5}
        refreshExpiredTiles={true}
        ref={mapRef}
        maxTileCacheSize={500}
      >
        <AttributionControl
          customAttribution={`Periopsis ${new Date().getFullYear()}`}
          compact={true}
        />
        <FullscreenControl />
        <GeolocateControl
          fitBoundsOptions={{ maxZoom: 30 }}
          showAccuracyCircle={false}
        />

        <NavigationControl visualizePitch={true} />
        <ScaleControl />

        {children}
      </Map>
    </div>
  );
};

export default MapSW;
