import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import classes from './AddNewUserSection.module.scss';

interface IProps {
  onAdd: () => void;
  isAdding: boolean;
}

const AddNewUserSection = ({ onAdd, isAdding }: IProps) => {
  if (isAdding) {
    return null;
  }

  return (
    <div className={classes.AddUserSection}>
      <HeadingBar
        heading='Add New User'
        headingSize={3}
        headingType='primary'
      />
      <p>
        You can click the "Add" button to activate the user registration form.
        This form will register the user in the Periopsis System and the
        Organization Users List. After registering the user in the organization
        users list, you can select one or multiple users to add them to the
        Users of the Current Contract. Once added to the Current Users of
        Contract List, users will initially have no "Actions" and "Admin"
        authorization. To assign authorizations, select the users on the left
        side of their name in the Current Users of Contract list. After
        assigning authorizations, click the activated "Update" button to assign
        users to the current contract. You can designate a user as an actions
        user, an admin user, both, or only a viewer. Information about each
        authorization is provided in the information title of the action,
        visible when you click on the info (i) icon at the top right of the
        authorization name.
      </p>
      <ActionContainer className={classes.AddNewUserActions}>
        <StandardButton
          label='Add New User to Organization Users List'
          btnType='primary'
          type='button'
          onClick={onAdd}
        />
      </ActionContainer>
    </div>
  );
};

export default AddNewUserSection;
