import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../app/store';
import * as DOMPurify from 'dompurify';

interface IProps {
  dispatchAction?: (data: any) => {
    payload: any;
    type: string;
  };
  localStorageName: string;
}

const useDropDownSelection = ({ localStorageName, dispatchAction }: IProps) => {
  const [selected, setSelectedLocally] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const storedData = localStorage.getItem(localStorageName);

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      let id = parsedData._id || parsedData.id;
      const purifiedId = DOMPurify.sanitize(id);
      setSelectedLocally(purifiedId);

      if (dispatchAction) {
        dispatch(dispatchAction(parsedData));
      }
    }
  }, [localStorageName, dispatch, dispatchAction]);

  return { selected, setSelectedLocally };
};

export default useDropDownSelection;
