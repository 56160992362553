import {
  useGetAdminUserInvoicesAndReceiptsQuery,
  useGetDocumentDetailsQuery,
} from '../../../../features/userAdmin/userAdminSlice';
import { useSearchParams } from 'react-router-dom';
import ContentContainer from '../../LoginRegister/SignUp/RegistrationCheckout/ContentContainer/ContentContainer';
import DocumentCard from './DocumentCard/DocumentCard';
import { Currency } from '../../../../tsTypes/types.model';
import DocumentListContainer from './DocumentListContainer/DocumentListContainer';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import ErrorControlComponent from '../../../UI/ErrorControlComponent/ErrorControlComponent';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import { paymentType } from '../../../../tsTypes/types.components';
import { Status } from '../../../../tsTypes/enums';
import { FaFileInvoice, FaFilePdf } from 'react-icons/fa';
import {
  IInvoiceCardResponse,
  IProformaCardResponse,
  IReceiptCardResponse,
} from '../../../../tsTypes/interfaces';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import { documentType } from '../../../../tsTypes/types';
import {
  ICheckoutContract,
  ICheckoutProforma,
  ICheckoutUserData,
} from '../../../../tsTypes/checkout.interfaces';
import ProformaInvoicePDF from '../../../pdfComponents/ProformaInvoice/ProformaInvoicePDF';
import { usePDF } from '@react-pdf/renderer';
import { Fragment, useEffect, useState } from 'react';
import classes from './InvoicesAndReceipts.module.scss';
import AdminPageContainer from '../AdminPageContainer/AdminPageContainer';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import ProformaAndContract from '../../ProformaInvoiceView/ProformaAndContract/ProformaAndContract';
import DataRow from '../../../UI/DataRow/DataRow';
import OrderDataCard from '../../LoginRegister/SignUp/RegistrationCheckout/OrderDetailsSection/OrderDataCard/OrderDataCard';
import ListContainer from '../../LoginRegister/SignUp/RegistrationCheckout/OrderDetailsSection/ListContainer/ListContainer';
import getTotalPolygonPrice from '../../../../utils/getTotalPolygonPrice';
import CustomerDetails from '../../LoginRegister/SignUp/RegistrationCheckout/CustomerDetails/CustomerDetails';
import AddressContent from '../../LoginRegister/SignUp/RegistrationCheckout/AddressContent/AddressContent';

const InvoicesAndReceipts = () => {
  const [pageQuery, setQuery] = useSearchParams();
  const page = pageQuery.get('page');
  const contractId = pageQuery.get('contract') || '';
  const document = pageQuery.get('doc');
  const docType = pageQuery.get('type');

  const {
    isLoading: isLoadingInvoices,
    isError: isInvoicesFetchError,
    error,
    data: documents,
  } = useGetAdminUserInvoicesAndReceiptsQuery(contractId, {
    skip: !contractId || !page || page !== 'invoices',
  });

  const {
    isLoading: isLoadingDocument,
    isError: isDocumentError,
    isSuccess: isDocumentDetailsSuccess,
    data: documentDetails,
  } = useGetDocumentDetailsQuery(
    {
      contractId: contractId || '',
      documentId: document || '',
      documentType: docType as documentType,
    },
    { skip: !contractId || !document || !docType }
  );

  let invoiceList: IInvoiceCardResponse[] = [];
  let receiptList: IReceiptCardResponse[] = [];
  let proformaList: IProformaCardResponse[] = [];
  const [showSubservices, setShowSubservices] = useState(false);
  const [showPolygons, setShowPolygons] = useState(false);

  let invoiceListComponent: React.ReactNode = null;
  let receiptListComponent: React.ReactNode = null;
  let proformaListComponent: React.ReactNode = null;
  let pdfDoc: React.ReactNode | null = null;

  let documentData: ICheckoutProforma | null =
    documentDetails !== undefined && typeof documentDetails === 'object'
      ? documentDetails.document
      : null;
  let contract: ICheckoutContract | null =
    documentDetails !== undefined && typeof documentDetails === 'object'
      ? documentDetails.contract
      : null;
  let userData: ICheckoutUserData | null =
    documentDetails !== undefined && typeof documentDetails === 'object'
      ? documentDetails.user
      : null;

  const doc = (
    <ProformaInvoicePDF
      proformaInvoice={documentData}
      contract={contract}
      user={userData}
    />
  );

  const [instance, update] = usePDF({ document: doc });

  useEffect(() => {
    if (isDocumentDetailsSuccess) {
      update(doc);
    }

    // eslint-disable-next-line
  }, [isDocumentDetailsSuccess]);

  if (isLoadingInvoices) {
    return (
      <FetchingReports message='Fetching Invoices and Receipts. Please wait...' />
    );
  }

  if (isInvoicesFetchError) {
    return (
      <ErrorControlComponent error={error}>
        <NoDataFound message="Something unexpected happened while trying to fetch your invoices and receipts. Please give it another shot a little later. If the issue persists, don't hesitate to reach out for assistance. We apologize for any inconvenience." />
      </ErrorControlComponent>
    );
  }

  const showSelectedDocumentDetails = (
    docNumber: string,
    docType: documentType
  ) => {
    if (page && contractId) {
      setQuery({ page, contract: contractId, doc: docNumber, type: docType });
    }
  };

  const closeDocumentDetails = () => {
    if (page && contractId) {
      setQuery({ page, contract: contractId });
    }
  };

  if (
    !isLoadingInvoices &&
    !isInvoicesFetchError &&
    documents &&
    typeof documents === 'object' &&
    'invoices' in documents &&
    documents.invoices &&
    Array.isArray(documents.invoices) &&
    'receipts' in documents &&
    documents.receipts &&
    Array.isArray(documents.receipts) &&
    'proformaInvoices' in documents &&
    documents.proformaInvoices &&
    Array.isArray(documents.proformaInvoices)
  ) {
    invoiceList = documents.invoices;
    receiptList = documents.receipts;
    proformaList = documents.proformaInvoices;
  }

  if (invoiceList.length > 0) {
    invoiceListComponent = (
      <DocumentListContainer heading='Invoices'>
        {invoiceList.map((doc) => (
          <DocumentCard
            documentNumber={doc.invoiceNumber}
            documentDate={new Date(doc.invoiceDate)}
            documentType='invoice'
            paymentType={doc.paymentType as paymentType}
            serviceType={doc.serviceType}
            overallTotal={doc.overallTotal}
            currency={doc.selectedPackage.currency as Currency}
            status={doc.status as Status}
            dueDate={new Date(doc.dueDate)}
            billingPeriod={{
              startDate: new Date(doc.billingPeriod.startDate),
              endDate: new Date(doc.billingPeriod.endDate),
            }}
            key={doc.invoiceNumber}
            onSelect={showSelectedDocumentDetails}
          />
        ))}
      </DocumentListContainer>
    );
  }

  if (receiptList.length > 0) {
    receiptListComponent = (
      <DocumentListContainer heading='Receipts'>
        {receiptList.map((doc) => (
          <DocumentCard
            documentNumber={doc.receiptNumber}
            documentDate={new Date(doc.receiptDate)}
            documentType='receipt'
            paymentType={doc.paymentType as paymentType}
            serviceType={doc.serviceType}
            overallTotal={doc.overallTotal}
            currency={doc.selectedPackage.currency as Currency}
            billingPeriod={{
              startDate: new Date(doc.billingPeriod.startDate),
              endDate: new Date(doc.billingPeriod.endDate),
            }}
            datePaid={new Date(doc.datePaid)}
            key={doc.receiptNumber}
            onSelect={showSelectedDocumentDetails}
          />
        ))}
      </DocumentListContainer>
    );
  }

  if (proformaList.length > 0) {
    proformaListComponent = (
      <DocumentListContainer heading='Proforma Invoices'>
        {proformaList.map((doc) => (
          <DocumentCard
            documentNumber={doc.proformaInvoiceNumber}
            documentDate={new Date(doc.invoiceDate)}
            documentType='proforma'
            paymentType={doc.paymentType as paymentType}
            serviceType={doc.serviceType}
            overallTotal={doc.overallTotal}
            currency={doc.selectedPackage.currency as Currency}
            status={doc.status as Status}
            dueDate={new Date(doc.dueDate)}
            key={doc.proformaInvoiceNumber}
            onSelect={showSelectedDocumentDetails}
          />
        ))}
      </DocumentListContainer>
    );
  }

  if (
    proformaList.length === 0 &&
    receiptList.length === 0 &&
    invoiceList.length === 0 &&
    !isLoadingInvoices &&
    !isInvoicesFetchError
  ) {
    return (
      <NoDataFound
        icon={<FaFileInvoice />}
        message="At the moment, you don't have any documents related to purchases or offers, such as invoices, receipts, or proforma invoices. If you're expecting some, they might be on the way or not available yet. Feel free to check back later or contact support if you have any concerns. Thank you!"
      />
    );
  }

  if (isLoadingDocument) {
    return (
      <FetchingReports message='Preparing document details. Please wait...' />
    );
  }

  if (isDocumentError) {
    return (
      <AdminPageContainer>
        <ActionContainer setup={{ justifyContent: 'space-between' }}>
          <StandardButton
            type='button'
            btnType='orange'
            label='Close'
            onClick={closeDocumentDetails}
          />
        </ActionContainer>
        <NoDataFound message='An error occured while fetching the document details. Please try again later.' />
      </AdminPageContainer>
    );
  }

  if (instance && instance.url && documentData && contract) {
    pdfDoc = (
      <a
        href={instance.url}
        download={`Periopsis_${
          documentData.proformaInvoiceNumber
        }_${new Date().getTime()}.pdf`}
        title='Download Proforma Invoice'
        className={classes.DownloadLink}
      >
        <FaFilePdf /> Download Proforma Invoice
      </a>
    );
  }

  if (
    document &&
    docType &&
    isDocumentDetailsSuccess &&
    documentDetails &&
    contract &&
    documentData &&
    userData
  ) {
    let hasSubservices = documentData.selectedPackage.subServices.length > 0;
    const subServices = documentData.selectedPackage.subServices;
    const subservicesHeading = showSubservices
      ? 'Selected Subservices'
      : 'Subservices';

    const polygons = contract.polygons;

    const showSubServicesHandler = () => {
      setShowSubservices((prevState) => !prevState);
    };

    const showPolygonsHandler = () => {
      setShowPolygons((prevState) => !prevState);
    };

    return (
      <AdminPageContainer>
        <ActionContainer setup={{ justifyContent: 'space-between' }}>
          <StandardButton
            type='button'
            btnType='orange'
            label='Close'
            onClick={closeDocumentDetails}
          />
          {pdfDoc}
        </ActionContainer>

        <ProformaAndContract
          proformaInvoice={documentData}
          contract={contract}
        />
        <OrderDataCard heading='Selection Details'>
          <DataRow
            heading='Selected Service Type'
            data={documentData.serviceType}
            removeSeperator
          />
          <DataRow
            heading='Selected Package'
            data={documentData.selectedPackage.packageName}
            removeSeperator
          />
          <DataRow
            heading='Monthly Price'
            data={`${documentData.selectedPackage.currency}${documentData.selectedPackage.monthlyPrice}${documentData.selectedPackage.priceUnit}`}
            removeSeperator
          />
          <DataRow
            heading='Duration'
            data={`${documentData.selectedPackage.duration} ${documentData.selectedPackage.durationUnit}`}
            removeSeperator
          />
          <DataRow
            heading='Package Area Limit'
            data={`${documentData.selectedPackage.areaLimit} ${documentData.selectedPackage.areaLimitUnit}`}
            removeSeperator
          />
          <DataRow
            heading='Selected Total Area'
            data={`${documentData.totalArea} ${documentData.areaUnit}`}
            removeSeperator
          />
          <DataRow
            heading='User Limit'
            data={documentData.selectedPackage.userLimit.toString()}
            removeSeperator
          />

          {hasSubservices ? (
            <ListContainer
              heading={`${subservicesHeading} (${subServices.length} subservices selected)`}
              onExpand={showSubServicesHandler}
              isExpanded={showSubservices}
              expandMessage={` This section displays the list of subservices you've selected.
                  To see the details, simply click the icon located to the right
                  of the heading to expand the list.`}
            >
              <ul
                className={`${classes.SubServiceListWrapper} ${
                  showSubservices ? classes.Show : ''
                }`}
              >
                {subServices.map((subservice) => (
                  <li key={subservice._id}>
                    <DataRow
                      heading='Subservice'
                      data={subservice.subServiceName}
                      removeSeperator
                    />
                    <DataRow
                      heading='Price'
                      data={`${subservice.currency}${subservice.unitPrice}/month`}
                      removeSeperator
                    />
                  </li>
                ))}
              </ul>
            </ListContainer>
          ) : null}
          <ListContainer
            heading={`Selected Polygons (${polygons.length} polygons selected)`}
            onExpand={showPolygonsHandler}
            isExpanded={showPolygons}
            expandMessage={` This section displays the list of polygons you've selected.
                  To see the details, simply click the icon located to the right
                  of the heading to expand the list.`}
          >
            <ul
              className={`${classes.SubServiceListWrapper} ${
                showPolygons ? classes.Show : ''
              }`}
            >
              {polygons.map((polygon) => (
                <li key={polygon.mid}>
                  <DataRow
                    heading='Polygon Id'
                    data={polygon.mid}
                    removeSeperator
                  />

                  {polygon.polygonName ? (
                    <DataRow
                      heading='Polygon Name'
                      data={polygon.polygonName}
                      removeSeperator
                    />
                  ) : null}
                  <DataRow
                    heading='Area'
                    data={`${polygon.area} ${polygon.areaUnit}`}
                    removeSeperator
                  />
                  {documentData ? (
                    <DataRow
                      heading='Polygon Price'
                      data={`${
                        documentData.selectedPackage.currency
                      }${getTotalPolygonPrice(
                        polygon.area,
                        documentData.selectedPackage.monthlyPrice
                      )}/Month`}
                    />
                  ) : null}
                  <DataRow
                    heading='District'
                    data={polygon.district}
                    removeSeperator
                  />
                  {polygon.province ? (
                    <DataRow
                      heading='Province'
                      data={polygon.province}
                      removeSeperator
                    />
                  ) : null}
                  <DataRow
                    heading='Country'
                    data={polygon.country}
                    removeSeperator
                  />
                </li>
              ))}
            </ul>
          </ListContainer>
        </OrderDataCard>
        <OrderDataCard heading={'Customer Details'}>
          <Fragment>
            <CustomerDetails
              customerEmail={documentData.customerEmail}
              customerName={documentData.customerName}
              userPhone={userData ? userData.userPhone : ''}
            />
            <AddressContent
              proformaInvoice={documentData}
              user={userData}
              onAddressUpdate={() => {}}
              isAddressUpdatable={false}
            />
          </Fragment>
        </OrderDataCard>
        <OrderDataCard heading='Pricing Details'>
          <DataRow
            heading='Polygon Total Area Price'
            data={`${documentData.selectedPackage.currency}${(
              documentData.totalAmount - documentData.selectedPackage.subTotal
            ).toFixed(2)}`}
            removeSeperator
          />
          <DataRow
            heading='Subservices Price'
            data={`${
              documentData.selectedPackage.currency
            }${documentData.selectedPackage.subTotal.toFixed(2)}`}
            removeSeperator
          />
          <DataRow
            heading='Discount Rate'
            data={`${documentData.discountRate.toFixed(2)}%`}
            removeSeperator
          />
          <DataRow
            heading='Discount Amount'
            data={`${
              documentData.selectedPackage.currency
            }${documentData.discountAmount.toFixed(2)}`}
            removeSeperator
          />
          <DataRow
            heading='VAT Rate'
            data={`${documentData.VATRate.toFixed(2)}%`}
            removeSeperator
          />
          <DataRow
            heading='VAT Amount'
            data={`${
              documentData.selectedPackage.currency
            }${documentData.VATAmount.toFixed(2)}`}
            removeSeperator
          />
          <DataRow
            heading='Overall Total'
            data={`${
              documentData.selectedPackage.currency
            }${documentData.overallTotal.toFixed(2)} / month`}
            removeSeperator
            className={classes.OverallTotal}
          />
          <DataRow
            heading={`Service ${documentData.selectedPackage.duration} ${documentData.selectedPackage.durationUnit} Total Amount`}
            data={`${documentData.selectedPackage.currency}${(
              (documentData.selectedPackage.duration / 30) *
              documentData.overallTotal
            ).toFixed(2)}`}
            className={`${classes.OverallTotal} ${classes.NoBorder}`}
            removeSeperator
          />
        </OrderDataCard>

        <ActionContainer setup={{ justifyContent: 'space-between' }}>
          <StandardButton
            type='button'
            btnType='orange'
            label='Close'
            onClick={closeDocumentDetails}
          />
          {pdfDoc}
        </ActionContainer>
      </AdminPageContainer>
    );
  }

  return (
    <ContentContainer gap='2.3rem'>
      {proformaListComponent}
      {invoiceListComponent}
      {receiptListComponent}
    </ContentContainer>
  );
};

export default InvoicesAndReceipts;
