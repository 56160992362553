import { ChangeEvent, useState, useEffect } from 'react';
import Input from '../../FormElements/Input/Input';
import useEscape from '../../../../CustomHooks/useEscape';
import { BsFillExclamationDiamondFill } from 'react-icons/bs';
import { CountryAlfa2Code, IListObject } from '../../../../tsTypes/types.model';
import * as DOMPurify from 'dompurify';
import classes from './CountryDropDown.module.scss';
import { AiOutlineEnter } from 'react-icons/ai';
import getTranslationKey from '../../../../utils/getTranslationKey';
import { useTranslation } from 'react-i18next';

interface IProps {
  countries: IListObject[];
  onSelect: (country: string, countryCode: CountryAlfa2Code) => void;
  selectedCountryData: { country: string; countryCode: CountryAlfa2Code };
  disabled?: boolean;
}

const CountryDropDown = ({
  countries,
  onSelect,
  selectedCountryData,
  disabled,
}: IProps) => {
  // const [selected, setSelected] = useState('');
  const [show, setShow] = useState(false);
  const [mount, setMount] = useState(false);
  const [search, setSearch] = useState('');
  let selected = '';
  const { t } = useTranslation();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let innerTimer: NodeJS.Timeout;
    const outsideClickHandler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (
        target.id !== 'dropdown-btn' &&
        target.id !== 'Search-Address-Country' &&
        target.id !== 'country-name'
      ) {
        timer = setTimeout(() => {
          setShow(false);

          innerTimer = setTimeout(() => {
            setMount(false);
          }, 200);
        }, 200);
      }
    };

    document.addEventListener('mouseup', outsideClickHandler);

    return () => {
      document.removeEventListener('mouseup', outsideClickHandler);
      if (timer) {
        clearTimeout(timer);
      }

      if (innerTimer) {
        clearTimeout(innerTimer);
      }
    };
  }, []);

  useEscape({
    setterValue: show,
    onEscape: () => {
      const timer = setTimeout(() => {
        setShow(false);
        clearTimeout(timer);
        const innerTimer = setTimeout(() => {
          setMount(false);
          clearTimeout(innerTimer);
        }, 200);
      }, 200);
    },
  });

  if (!countries) {
    return null;
  }

  if (selectedCountryData) {
    selected = selectedCountryData.country;
  }

  const showMenuHandler = () => {
    if (disabled) {
      return;
    }

    if (!show) {
      setMount(true);

      const timer = setTimeout(() => {
        setShow(true);
        clearTimeout(timer);
      }, 50);
    } else {
      setShow(false);

      const timer = setTimeout(() => {
        setMount(false);
        clearTimeout(timer);
      }, 100);
    }

    setSearch('');
  };

  const closeMenu = () => {
    setSearch('');
    setShow(false);

    const timer = setTimeout(() => {
      setMount(false);
      clearTimeout(timer);
    }, 100);
  };

  const selectCountryHandler = (
    country: string,
    countryCode: CountryAlfa2Code
  ) => {
    if (!country || disabled) {
      return;
    }

    if (onSelect && typeof onSelect === 'function') {
      const purifiedCountry = DOMPurify.sanitize(country);
      const purifiedCode = DOMPurify.sanitize(countryCode);

      onSelect(purifiedCountry, purifiedCode as CountryAlfa2Code);
    }

    closeMenu();
  };

  const searchCountryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    setSearch(searchValue);
  };

  const filteredCountries = countries.filter((cnt) =>
    cnt.title.toLowerCase().includes(search.toLowerCase())
  );

  const countrySelectByEnterHandler = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' || e.code === 'Enter' || e.code === 'NumpadEnter') {
      if (filteredCountries && filteredCountries.length > 0) {
        const [selected] = filteredCountries;
        const filteredCountry = DOMPurify.sanitize(selected.title);
        const filteredCountryCode = DOMPurify.sanitize(selected.id);
        onSelect(filteredCountry, filteredCountryCode as CountryAlfa2Code);
      }

      closeMenu();
    }
  };

  const filterLength = filteredCountries.length;

  return (
    <div className={classes.MainContainer}>
      <div className={classes.DropdownContainer}>
        <button
          className={`${classes.DropDownButton} ${
            show ? classes.RemoveBorder : ''
          }`}
          type='button'
          onClick={showMenuHandler}
          id='dropdown-btn'
        ></button>
        <div className={classes.Selected}>
          <Input
            type='text'
            name='country-name'
            onChange={() => {}}
            value={t(`auth:${getTranslationKey(selected)}`)}
            disabled={true}
            placeholder={t('auth:Select_Address_Country')}
          />
        </div>
      </div>
      {mount && (
        <div
          className={`${classes.Options} ${show ? classes.Show : ''}`}
          style={{ height: filterLength <= 3 ? 'max-content' : undefined }}
        >
          <Input
            type='search'
            placeholder={t('auth:Search_Country')}
            name='Search-Address-Country'
            onChange={!disabled ? searchCountryHandler : () => {}}
            value={search}
            disabled={disabled}
            onKeyUp={countrySelectByEnterHandler}
          />
          <div className={classes.CountryList}>
            {filteredCountries.map((data) => (
              <button
                type='button'
                className={`${classes.Option} ${
                  selected === data.title ? classes.Selected : ''
                }`}
                onClick={() =>
                  selectCountryHandler(data.title, data.id as CountryAlfa2Code)
                }
                key={data.id}
              >
                <div>
                  <img
                    src={
                      data.value && typeof data.value === 'string'
                        ? `/images/flags/${data.value.toLowerCase()}.png`
                        : ''
                    }
                    alt={`${data.title} Flag`}
                    title={`${data.title} Flag`}
                    width={32 * 0.6}
                    height={24 * 0.6}
                  />
                </div>

                <span>{t(`auth:${getTranslationKey(data.title)}`)}</span>
              </button>
            ))}
            {filterLength === 0 ? (
              <div className={classes.NoCountry}>
                <BsFillExclamationDiamondFill />
                <p>{t('auth:No_Country_Found')}</p>
              </div>
            ) : null}
          </div>
          {filterLength === 1 ? (
            <div className={classes.PressEnter}>
              <AiOutlineEnter />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CountryDropDown;
