import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllUIValues } from '../../../features/UI/uiSlice';
import NoDataFound from '../../UI/NoDataFound/NoDataFound';
import ListMap from './ListMap/ListMap';
import { TbFaceIdError } from 'react-icons/tb';
import {
  selectAllIncidentValues,
  useGetContractIncidentsQuery,
  useGetContractServiceTypeIncidentsQuery,
  useGetContractsQuery,
  useRegisterContractToGISServerQuery,
} from '../../../features/incidents/incidentsSlice';
import { IContractIncident, IUserContract } from '../../../tsTypes/interfaces';
import IncidentList from './Incidents/IncidentList/IncidentList';
import IncidentFilteringContainer from './IncidentFiltering/IncidentFilteringContainer/IncidentFilteringContainer';
import getFilteredIncidents from '../../../utils/getFilteredIncidents';
import getTimeFilteredIncidents from '../../../utils/getTimeFilteredIncidents';
import getTypeFilteredIncidents from '../../../utils/getTypeFilteredIncidents';
import NoIncidentMessageContainer from './NoIncidentMessageContainer/NoIncidentMessageContainer';
import IncidentContentContainer from './IncidentContentContainer/IncidentContentContainer';
import { AppDispatch } from '../../../app/store';
import { setUserCurrentAuthorization } from '../../../features/auth/authSlice';
import useSessionLogout from '../../../CustomHooks/useSessionLogout';
import classes from './Dashboard.module.scss';
import NoContract from '../../UI/NoContract/NoContract';
import { ImBlocked } from 'react-icons/im';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import getUserContractsResult from '../../../utils/ContractUsersUtils/getUserContractsResult';
import { Coordinates } from '../../../tsTypes/types';
import DashboardInitLoader from './DashboardInitLoader/DashboardInitLoader';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const [selectedContract, setSelectedContract] = useState('');
  const [contractServiceTypeId, setContractServiceTypeId] = useState('');
  const { status, fromDate, toDate, selectedIncidentTypeId } = useSelector(
    selectAllIncidentValues
  );

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    isSuccess,
    data: userContractsData,
    error,
  } = useGetContractsQuery('contracts');
  const {
    isLoading: isLoadingIncidents,
    isError: isIncidentFetchError,
    error: incidentsError,
    data: incidentsOfContract,
    refetch: reFetchIncidentsOfContract,
  } = useGetContractIncidentsQuery(
    {
      contractId: selectedContract,
    },
    {
      skip: selectedContract === '',
      refetchOnMountOrArgChange: false,
    }
  );

  const { contracts: userContracts, contractsTerminated } =
    getUserContractsResult(userContractsData);

  //Add register to GIS Fetch process here :

  const { isSuccess: isRegistrationSuccess } =
    useRegisterContractToGISServerQuery(selectedContract, {
      skip:
        !incidentsOfContract ||
        (incidentsOfContract && incidentsOfContract.isRegisteredContract) ||
        (incidentsOfContract && incidentsOfContract.contractStatus !== 'Paid'),
      pollingInterval: 900000,
    });

  useSessionLogout({
    isError: isError || isIncidentFetchError,
    error: error || incidentsError,
  });

  const { data: incidentTypes } = useGetContractServiceTypeIncidentsQuery(
    contractServiceTypeId,
    {
      skip: contractServiceTypeId === '',
    }
  );

  let incidents: IContractIncident[] = [];
  let contracts: IUserContract[] = [];
  let totalNumberOfIncidents =
    incidentsOfContract &&
    typeof incidentsOfContract === 'object' &&
    'totalIncidents' in incidentsOfContract
      ? incidentsOfContract.totalIncidents
      : '';
  let centerCoords: Coordinates | [] = [];
  let isBlocked = false;
  let hasIncidents = false;

  const { showListMap } = useSelector(selectAllUIValues);

  const initFetch = useRef(true);

  const hasContracts =
    userContracts !== undefined &&
    Array.isArray(userContracts) &&
    userContracts.length > 0;

  let content: React.ReactNode = null;
  const hasIncidentsToFetch =
    userContracts && Array.isArray(userContracts) && userContracts.length > 0
      ? userContracts[0].hasIncidentsToFetch
      : false;

  const initContract: IUserContract | null =
    userContracts && Array.isArray(userContracts) && userContracts.length > 0
      ? userContracts[0]
      : null;

  useEffect(() => {
    if (
      isRegistrationSuccess &&
      incidentsOfContract &&
      !incidentsOfContract.isRegisteredContract
    ) {
      reFetchIncidentsOfContract();
    }

    // eslint-disable-next-line
  }, [isRegistrationSuccess, incidentsOfContract]);

  useEffect(() => {
    if (hasIncidentsToFetch && initFetch.current && isSuccess && initContract) {
      const selectedContractId = initContract.contractId;
      const selectedServiceTypeId = initContract.serviceTypeId;
      setSelectedContract(selectedContractId);
      setContractServiceTypeId(selectedServiceTypeId);
      localStorage.setItem('current-contract', selectedContractId);
      dispatch(setUserCurrentAuthorization(selectedContractId));
      initFetch.current = false;
    }
    // eslint-disable-next-line
  }, [hasIncidentsToFetch, isSuccess, initContract]);

  useEffect(() => {
    if (!hasContracts && !isLoading) {
      localStorage.removeItem('current-contract');
    }
  }, [hasContracts, isLoading]);

  if (isLoadingIncidents || isLoading) {
    return <DashboardInitLoader />;
  }

  if (isIncidentFetchError || isError) {
    return (
      <NoIncidentMessageContainer>
        <NoDataFound
          message={t('dashboard:unexpected_error')}
          icon={<TbFaceIdError />}
        />
      </NoIncidentMessageContainer>
    );
  }

  if (
    incidentsOfContract &&
    typeof incidentsOfContract === 'object' &&
    'incidents' in incidentsOfContract
  ) {
    incidents = incidentsOfContract.incidents;
    totalNumberOfIncidents = incidentsOfContract.totalIncidents;
    isBlocked = incidentsOfContract.isBlocked;
    hasIncidents = incidentsOfContract.hasIncidents;
    centerCoords = incidentsOfContract.centerCoordinates;
  }

  if (!hasContracts && !isBlocked) {
    return <NoContract hasTerminated={contractsTerminated} />;
  }

  if (incidents.length === 0 && isBlocked && hasContracts) {
    return (
      <NoDataFound
        icon={<ImBlocked />}
        message={t('dashboard:contract_restrict')}
      />
    );
  }

  if (!incidents || !incidentsOfContract) {
    return <NoDataFound message={t('dashboard:no_incidents_found')} />;
  }

  if (
    userContracts &&
    Array.isArray(userContracts) &&
    userContracts.length > 0
  ) {
    contracts = [...userContracts];
  }

  const filteredIncidents = getFilteredIncidents({ incidents, status });

  const timeFilteredIncidents = getTimeFilteredIncidents({
    incidents: filteredIncidents,
    fromDate,
    toDate,
  });

  const incidentsByType = getTypeFilteredIncidents({
    incidents: timeFilteredIncidents,
    incidentTypeId: selectedIncidentTypeId,
  });

  if (showListMap && incidentsByType && incidentsByType.length > 0) {
    content = (
      <div className={classes.ListWrapper}>
        <IncidentContentContainer>
          <IncidentList
            incidents={incidentsByType}
            totalNumberOfIncidents={Number(totalNumberOfIncidents)}
          />
        </IncidentContentContainer>
      </div>
    );
  } else if (!showListMap) {
    content = (
      <IncidentContentContainer className={classes.CenterMap}>
        <ListMap incidents={incidentsByType} centerCoords={centerCoords} />
      </IncidentContentContainer>
    );
  } else if (incidents.length === 0 && !hasIncidents) {
    content = (
      <NoIncidentMessageContainer>
        <NoDataFound
          icon={<FaFilterCircleXmark />}
          message={t('dashboard:no_reported_incident')}
        />
      </NoIncidentMessageContainer>
    );
  } else {
    content = (
      <NoIncidentMessageContainer>
        <NoDataFound message={t('dashboard:filter_no_incident')} />
      </NoIncidentMessageContainer>
    );
  }

  return (
    <div className={classes.DashboardContainer}>
      <IncidentFilteringContainer
        contracts={contracts}
        incidentTypes={incidentTypes}
        incidentCount={incidentsByType ? incidentsByType.length : 0}
      />
      {content}
    </div>
  );
};

export default Dashboard;
