import { useState } from 'react';
import { Status } from '../../../../../tsTypes/enums';
import { Currency, paymentType } from '../../../../../tsTypes/types.components';
import classes from './DocumentCard.module.scss';
import { documentType } from '../../../../../tsTypes/types';

interface IProps {
  documentType: documentType;
  documentNumber: string;
  documentDate: Date;
  paymentType: paymentType;
  status?: Status;
  serviceType: string;
  overallTotal: number;
  currency: Currency;
  billingPeriod?: { startDate: Date; endDate: Date };
  dueDate?: Date;
  datePaid?: Date;
  onSelect: (documentNumber: string, documentType: documentType) => void;
}

const DocumentCard = ({
  documentNumber,
  documentDate,
  documentType,
  paymentType,
  status,
  serviceType,
  overallTotal,
  currency,
  billingPeriod,
  dueDate,
  datePaid,
  onSelect,
}: IProps) => {
  let docBasedContent: React.ReactNode = null;
  let datePaidContent: React.ReactNode = null;

  const [showMessage, setShowMessage] = useState(false);

  if (
    (documentType === 'invoice' || documentType === 'receipt') &&
    billingPeriod
  ) {
    docBasedContent = (
      <div className={classes.BillingPeriod}>
        <p>Billing Period</p>
        <div className={classes.Periods}>
          <p>
            {billingPeriod.startDate.toLocaleDateString('en', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })}
          </p>{' '}
          -{' '}
          <p>
            {billingPeriod.endDate.toLocaleDateString('en', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })}
          </p>
        </div>
      </div>
    );
  } else if (
    (documentType === 'proforma' || documentType === 'invoice') &&
    status &&
    dueDate
  ) {
    docBasedContent =
      status === 'Paid' ? (
        <div className={classes.ProformaInvoiceDoc}>
          <p>Status</p>
          <p>{status}</p>
        </div>
      ) : (
        <div className={classes.ProformaInvoiceDoc}>
          <p>Due Date</p>
          <p>
            {dueDate.toLocaleDateString('en', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })}
          </p>
        </div>
      );
  }

  if (documentType === 'receipt' && datePaid) {
    datePaidContent = (
      <div className={classes.ProformaInvoiceDoc}>
        <p>Date Paid</p>
        <p>
          {datePaid.toLocaleDateString('en', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          })}
        </p>
      </div>
    );
  }

  return (
    <button
      className={`${classes.DocumentContainer} ${
        documentType === 'proforma'
          ? classes.Proforma
          : documentType === 'invoice'
          ? classes.Invoice
          : classes.Receipt
      }`}
      onClick={() => onSelect(documentNumber, documentType)}
      onMouseEnter={() => setShowMessage(true)}
      onMouseLeave={() => setShowMessage(false)}
    >
      <div className={classes.ServiceType}>
        <p>{serviceType}</p>
        {documentType === 'proforma' ? (
          <span>Proforma Invoice</span>
        ) : documentType === 'invoice' ? (
          <span>Invoice</span>
        ) : (
          <span>Receipt</span>
        )}
      </div>
      <div className={classes.DocumentId}>
        <p>{documentNumber}</p>
        <p>
          {new Date(documentDate).toLocaleDateString('en', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          })}
        </p>
      </div>
      {docBasedContent}
      {datePaidContent}
      <div className={classes.PaymentData}>
        <p>{paymentType}</p>
        <div className={classes.Overall}>
          <p>Overall Total</p>
          <p>
            {currency}
            {overallTotal.toFixed(2)}
          </p>
        </div>
      </div>
      <div
        className={`${classes.ClickMessage} ${
          showMessage ? classes.Visible : ''
        } ${
          documentType === 'proforma'
            ? classes.Proforma
            : documentType === 'invoice'
            ? classes.Invoice
            : classes.Receipt
        }`}
      >
        Click to See Details
      </div>
    </button>
  );
};

export default DocumentCard;
