import { useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import SelectionContainer from '../SelectionContainer/SelectionContainer';
import {
  approveInvoiceDetails,
  nextPage,
  selectAllRegistrationFormValues,
  setSelectedDistrict,
  setSelectedProvince,
  useGetDistrictsQuery,
} from '../../../../../../features/registration/registrationSlice';
import SectionExplanation from '../SectionExplanation/SectionExplanation';
import { FaMapSigns } from 'react-icons/fa';
import SelectionForm from '../SelectionForm/SelectionForm';
import { toast } from 'react-toastify';
import { IRegistrationSelectionProps } from '../../../../../../tsTypes/types.components';
import { AppDispatch } from '../../../../../../app/store';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { IListObject } from '../../../../../../tsTypes/types.model';
import Dropdown from '../../../../../UI/FormElements/Dropdown/Dropdown';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import { catchError } from '../../../../../../utils/fetches';
import useDropDownSelection from '../../../../../../CustomHooks/useDropDownSelection';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

//This will contain two option, Select on List | Select on Map
const DistrictSelection = ({ pageNumber }: IRegistrationSelectionProps) => {
  const [showCheckMarkProvince, setShowCheckMarkProvince] = useState(false);
  const [showCheckMarkDistrict, setShowCheckMarkDistrict] = useState(false);
  const { currentPageNumber, selectedCountry, selectedProvince } = useSelector(
    selectAllRegistrationFormValues
  );
  const localStorageNameDistrict = 'selected-district';
  const localStorageNameProvince = 'selected-province';
  const { t } = useTranslation();

  const {
    selected: selectedDistrictLocally,
    setSelectedLocally: setSelectedDistrictLocally,
  } = useDropDownSelection({
    localStorageName: localStorageNameDistrict,
  });
  const {
    selected: selectedProvinceLocally,
    setSelectedLocally: setSelectedProvinceLocally,
  } = useDropDownSelection({
    localStorageName: localStorageNameProvince,
  });

  const selectedCountryId = selectedCountry ? selectedCountry._id : '';

  const { isLoading, isError, error, data } = useGetDistrictsQuery(
    selectedCountryId,
    {
      skip: currentPageNumber !== 3 && selectedCountryId === '',
    }
  );

  const hasProvince = selectedCountry ? selectedCountry.hasProvices : false;

  const dispatch = useDispatch<AppDispatch>();

  let districtsToUse: IListObject[] = [];
  let provincesToUse: IListObject[] = [];
  let dropDowns: React.ReactNode | null = null;
  let districtSelectionList: IListObject[] = [];

  if (hasProvince && data && !isLoading && !isError) {
    provincesToUse = data.map((province) => ({
      id: province._id,
      title: province.provinceName,
      value: province._id,
    }));
  }

  if (hasProvince && selectedProvince && data) {
    const provinceAtData = data.find(
      (prov) => prov._id === selectedProvince._id
    );

    if (provinceAtData) {
      const districts = provinceAtData.provinceDistricts;
      districtsToUse = districts.map((dist) => ({
        id: dist._id,
        title: t(`auth:${getTranslationKey(dist.name)}`),
        value: dist._id,
      }));

      districtSelectionList = districts.map((dist) => ({
        id: dist._id,
        title: dist.name,
        value: dist._id,
      }));
    }
  }

  if (!hasProvince && data) {
    districtsToUse = data.map((dist) => ({
      id: dist._id,
      title: t(`auth:${getTranslationKey(dist.provinceName)}`),
      value: dist._id,
    }));

    districtSelectionList = data.map((dist) => ({
      id: dist._id,
      title: dist.provinceName,
      value: dist._id,
    }));
  }

  const districtSelectHandler = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selected = e.target.value;

    const district = districtSelectionList.find((dist) => dist.id === selected);

    if (!district) {
      return;
    }

    setSelectedDistrictLocally(selected);

    const districtToDispatch = { _id: district.id, name: district.title };

    localStorage.setItem(
      localStorageNameDistrict,
      JSON.stringify(districtToDispatch)
    );

    localStorage.setItem('current-district', district.title);

    toast.success(
      <ToastifyStyledMessage
        singleMessage={t('auth:select_success_message')}
        heading={t('auth:next_page')}
      />,
      { autoClose: 1000 }
    );
    setShowCheckMarkDistrict(true);
    localStorage.removeItem('invoice-details-approved');

    batch(() => {
      dispatch(setSelectedDistrict(districtToDispatch));
      dispatch(approveInvoiceDetails(false));
      const timer = setTimeout(() => {
        dispatch(nextPage());
        toast.dismiss();
        clearTimeout(timer);
      }, 1600);
    });
  };

  const provinceSelectHandler = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selected = e.target.value;

    const province = provincesToUse.find((dist) => dist.id === selected);

    if (!province) {
      return;
    }

    setSelectedProvinceLocally(selected);

    const provinceToDispatch = { _id: province.id, name: province.title };
    localStorage.setItem(
      localStorageNameProvince,
      JSON.stringify(provinceToDispatch)
    );

    setShowCheckMarkProvince(true);
    dispatch(setSelectedProvince(provinceToDispatch));
  };

  if (hasProvince) {
    dropDowns = (
      <PresentationWrapper
        isLoading={isLoading}
        isError={isError}
        error={catchError(error)}
        message={t('auth:province_load_error')}
      >
        <SelectionForm showCheckMark={showCheckMarkProvince}>
          <Dropdown
            optionsList={provincesToUse}
            name='selectProvince'
            defaultValue={t('auth:Select_Province')}
            onSelect={provinceSelectHandler}
            value={selectedProvinceLocally}
          />
        </SelectionForm>
        {selectedProvince ? (
          <SelectionForm showCheckMark={showCheckMarkDistrict}>
            <Dropdown
              optionsList={districtsToUse}
              name='selectDistrict'
              defaultValue={t('auth:Select_District')}
              onSelect={districtSelectHandler}
              value={selectedDistrictLocally}
            />
          </SelectionForm>
        ) : null}
      </PresentationWrapper>
    );
  } else {
    dropDowns = (
      <PresentationWrapper
        isLoading={isLoading}
        isError={isError}
        error={catchError(error)}
        message={t('auth:district_load_error')}
      >
        <SelectionForm showCheckMark={showCheckMarkDistrict}>
          <Dropdown
            optionsList={districtsToUse}
            name='selectDistrict'
            defaultValue={t('auth:Select_District')}
            onSelect={districtSelectHandler}
            value={selectedDistrictLocally}
          />
        </SelectionForm>
      </PresentationWrapper>
    );
  }

  return (
    <SelectionContainer
      isInQueue={pageNumber ? pageNumber < currentPageNumber : false}
      isCompleted={pageNumber ? pageNumber > currentPageNumber : false}
    >
      <SectionExplanation
        heading={
          hasProvince
            ? t('auth:select_province_district')
            : t('auth:select_target_district')
        }
        description={
          hasProvince
            ? t('auth:select_province_district_desc')
            : t('auth:select_district_desc')
        }
        icon={<FaMapSigns size={25} />}
      />

      {dropDowns}
    </SelectionContainer>
  );
};

export default DistrictSelection;
