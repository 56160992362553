import { useSelector, useDispatch } from 'react-redux';
import { IoArrowForwardCircle, IoArrowBackCircle } from 'react-icons/io5';
import {
  nextPage,
  prevPage,
  selectAllRegistrationFormValues,
} from '../../../../../../features/registration/registrationSlice';
import { AppDispatch } from '../../../../../../app/store';
import { useRegisterUserMutation } from '../../../../../../features/auth/authSlice';
import classes from './PageNavigation.module.scss';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

const PageNavigation = () => {
  const {
    selectedCountry,
    selectedDistrict,
    selectedOrganizationType,
    selectedServiceType,
    currentPageNumber,
    selectedPackage,
    selectedOrganization,
    isInvoiceDetailsApproved,
    organizationHasExistingUser,
  } = useSelector(selectAllRegistrationFormValues);
  const [, { isLoading }] = useRegisterUserMutation();

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const goToPreviosPageHandler = () => {
    dispatch(prevPage());
  };

  const goToNextPageHandler = () => {
    dispatch(nextPage());
  };

  return (
    <div className={classes.PageNavigation}>
      {currentPageNumber > 1 && !isLoading ? (
        <div>
          <IoArrowBackCircle
            title={t('auth:Go_to_Previous')}
            onClick={goToPreviosPageHandler}
          />
        </div>
      ) : (
        <div>
          <IoArrowBackCircle
            title={t('auth:Go_to_Previous')}
            color='lightgray'
          />
        </div>
      )}
      <div className={classes.Selected}>
        {selectedOrganizationType?.value ? (
          <span>
            {t(`auth:${getTranslationKey(selectedOrganizationType.value)}`)}
          </span>
        ) : null}
        {selectedCountry?.name ? (
          <span>{t(`auth:${getTranslationKey(selectedCountry.name)}`)}</span>
        ) : null}
        {selectedDistrict ? (
          <span>{t(`auth:${getTranslationKey(selectedDistrict.name)}`)}</span>
        ) : null}
        {selectedServiceType ? (
          <span>
            {t(`dashboard:${getTranslationKey(selectedServiceType.title)}`)}
          </span>
        ) : null}
        {selectedPackage ? (
          <span>{t(`auth:${getTranslationKey(selectedPackage.name)}`)}</span>
        ) : null}
        {selectedOrganization ? (
          <span>{selectedOrganization.organizationName}</span>
        ) : null}
      </div>

      {(currentPageNumber === 1 && selectedOrganizationType) ||
      (currentPageNumber === 2 && selectedCountry) ||
      (currentPageNumber === 3 && selectedDistrict) ||
      (currentPageNumber === 4 && selectedServiceType) ||
      (currentPageNumber === 5 && selectedPackage) ||
      (currentPageNumber === 6 &&
        selectedOrganization &&
        !organizationHasExistingUser) ||
      (currentPageNumber === 7 && isInvoiceDetailsApproved) ? (
        <div>
          <IoArrowForwardCircle
            title={t('auth:Go_to_Next')}
            onClick={goToNextPageHandler}
          />
        </div>
      ) : (
        <div>
          <IoArrowForwardCircle
            title={t('auth:Go_to_Next')}
            color='lightgray'
          />
        </div>
      )}
    </div>
  );
};

export default PageNavigation;
