import { useState, useEffect, ChangeEvent } from 'react';
import {
  useGetAddressStructureQuery,
  useGetCountryPhonesQuery,
} from '../features/registration/registrationSlice';
import useLocalStorage from './useLocalStorage';
import * as DOMPurify from 'dompurify';
import {
  CountryAlfa2Code,
  Division,
  IAddress,
  PostalCode,
} from '../tsTypes/types.model';
import detectUserCountry from '../utils/detectUserCountry';
import { AddressValidation } from '../utils/addressUtilityFunctions/addressValidations';
import { frontendConstants } from '../constants/constants';
import { AddressType } from '../tsTypes/types.components';

interface IProps {
  userAddressLocalStorageName: string;
  moreAddressDetailsLocalStorageName: string;
  shouldSkip: boolean;
}

const useAddress = ({
  userAddressLocalStorageName,
  moreAddressDetailsLocalStorageName,
  shouldSkip,
}: IProps) => {
  const userAddressInitValue: IAddress = {
    addressType: { id: '', name: '' },
    countryDetails: { country: '', countryCode: '' as CountryAlfa2Code },
    streetAddress: { label: '', name: '' },
    division: { label: '' as Division, name: '' },
    subDivision: { label: '' as Division, name: '' },
    postalCode: { label: '' as PostalCode, name: '' },
    addressDetails: { label: '', name: '' },
    settlement: { label: '', name: '' },
  };

  const fieldInteractedInitState = {
    postalCode: false,
    division: false,
    subDivision: false,
    streetAddress: false,
    settlement: false,
  };

  const {
    result: userAddress,
    setResult: setUserAddress,
    isLocalStorageChecked,
  } = useLocalStorage<IAddress>({
    localStorageName: userAddressLocalStorageName,
    initialValue: userAddressInitValue,
  });

  const { result: moreAddressDetails, setResult: setMoreAddressDetails } =
    useLocalStorage<boolean>({
      localStorageName: moreAddressDetailsLocalStorageName,
      initialValue: false,
    });

  const [fieldInteracted, setFieldInteracted] = useState(
    fieldInteractedInitState
  );

  const {
    data: addressStructure,
    isLoading: isFetchingAddressStructure,
    isError: isAddressStructureFetchError,
    error: addressStructureError,
  } = useGetAddressStructureQuery(
    userAddress.countryDetails.countryCode as CountryAlfa2Code,
    {
      skip: userAddress.countryDetails.countryCode === ('' as CountryAlfa2Code),
    }
  );

  const {
    isLoading: isFetchingCountries,
    isError: isCountryFetchError,
    error: countryFetchError,
    data: countries,
  } = useGetCountryPhonesQuery('', { skip: shouldSkip });

  const hasCountryPhones = countries !== undefined && countries.length > 0;
  const countryDataCountryName =
    userAddress &&
    userAddress.countryDetails &&
    userAddress.countryDetails.country
      ? userAddress.countryDetails.country
      : '';

  useEffect(() => {
    if (hasCountryPhones && !countryDataCountryName && isLocalStorageChecked) {
      const userCountry = detectUserCountry({ countryPhones: countries });

      if (userCountry && !countryDataCountryName) {
        setUserAddress((prevStateData) => {
          const updatedData = {
            ...prevStateData,
            countryDetails: {
              country: userCountry.name,
              countryCode: userCountry.code,
            },
          };
          localStorage.setItem(
            userAddressLocalStorageName,
            JSON.stringify(updatedData)
          );

          return updatedData;
        });
      }
    }

    if (
      countryDataCountryName &&
      userAddress.countryDetails.country !== '' &&
      userAddress.countryDetails.countryCode !== ('' as CountryAlfa2Code) &&
      isLocalStorageChecked
    ) {
      setUserAddress((prevStateData) => {
        const updatedData = {
          ...prevStateData,
          countryDetails: {
            country: userAddress.countryDetails.country,
            countryCode: userAddress.countryDetails.countryCode,
          },
        };
        localStorage.setItem(
          userAddressLocalStorageName,
          JSON.stringify(updatedData)
        );

        return updatedData;
      });
    }
    // eslint-disable-next-line
  }, [hasCountryPhones, countryDataCountryName]);

  const moreAddressDetailsHandler = () => {
    if (moreAddressDetails) {
      setMoreAddressDetails(false);
      setUserAddress((prevAddress) => {
        const updateAddress = {
          ...prevAddress,
          addressDetails: { label: '', name: '' },
        };
        localStorage.setItem(
          userAddressLocalStorageName,
          JSON.stringify(updateAddress)
        );

        return updateAddress;
      });
      localStorage.removeItem(moreAddressDetailsLocalStorageName);
    } else {
      setMoreAddressDetails(true);
      localStorage.setItem(moreAddressDetailsLocalStorageName, 'true');
    }
  };

  const userAddressEntryHandler = (data: {
    label: string;
    name: string;
    dataInputType: string;
  }) => {
    const { label, name, dataInputType } = data;

    const purifiedType = DOMPurify.sanitize(label);
    const purifiedName = DOMPurify.sanitize(name);
    const purifiedDataInputType = DOMPurify.sanitize(dataInputType);

    setUserAddress((prevAddress) => {
      const updatedData = {
        ...prevAddress,
        [purifiedDataInputType]: { label: purifiedType, name: purifiedName },
      };

      localStorage.setItem(
        userAddressLocalStorageName,
        JSON.stringify(updatedData)
      );

      return updatedData;
    });

    setFieldInteracted((prevState) => ({
      ...prevState,
      [dataInputType]: true,
    }));
  };

  const addressCountrySelectHandler = (
    country: string,
    countryCode: CountryAlfa2Code
  ) => {
    if (
      country &&
      countryCode &&
      countryCode !== userAddress.countryDetails.countryCode
    ) {
      setUserAddress((prevStateData) => {
        const updatedData = {
          ...prevStateData,
          division: { label: '' as Division, name: '' },
          subDivision: { label: '' as Division, name: '' },
          postalCode: { label: '' as PostalCode, name: '' },
          settlement: { label: '', name: '' },
          countryDetails: { country, countryCode },
        };
        localStorage.setItem(
          userAddressLocalStorageName,
          JSON.stringify(updatedData)
        );

        return updatedData;
      });

      // setUserAddress((prevState) => ({
      //   ...prevState,
      //   division: { label: '' as Division, name: '' },
      //   subDivision: { label: '' as Division, name: '' },
      //   postalCode: { label: '' as PostalCode, name: '' },
      //   settlement: { label: '', name: '' },
      // }));
      setFieldInteracted(fieldInteractedInitState);
    }
  };

  const addressTypeSelectHandler = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedAddressTypeId = e.target.value;
    let selectedAddressType: AddressType = { id: '', name: '' };

    const addressTypeObj = frontendConstants.addressTypeOptionList.find(
      (addressType) => addressType.id === selectedAddressTypeId
    );

    if (addressTypeObj) {
      selectedAddressType = {
        id: addressTypeObj.id,
        name: addressTypeObj.title,
      };
    }

    setUserAddress((prevState) => {
      const updatedData = { ...prevState, addressType: selectedAddressType };

      localStorage.setItem(
        userAddressLocalStorageName,
        JSON.stringify(updatedData)
      );

      return updatedData;
    });
  };

  const validate = new AddressValidation(addressStructure, userAddress);

  const isValidPostalCode = validate.postalCode;
  const isValidStreetAddress = validate.streetAddress;
  const isValidDivision = validate.division;
  const isValidSubdivision = validate.subDivision;
  const isValidSettlement = validate.settlement;
  const isValidAddressType = validate.addressType;

  const isValidAddress = {
    isValidPostalCode: fieldInteracted.postalCode ? isValidPostalCode : true,
    isValidStreetAddress: fieldInteracted.streetAddress
      ? isValidStreetAddress
      : true,
    isValidDivision: fieldInteracted.division ? isValidDivision : true,
    isValidSubdivision: fieldInteracted.subDivision ? isValidSubdivision : true,
    isValidSettlement: fieldInteracted.settlement ? isValidSettlement : true,
  };

  const activateButton = {
    isValidPostalCode,
    isValidStreetAddress,
    isValidDivision,
    isValidSubdivision,
    isValidSettlement,
  };

  const clearAddress = () => {
    setUserAddress(userAddressInitValue);
    setFieldInteracted(fieldInteractedInitState);
  };

  const variableData = {
    countries,
    isFetchingCountries,
    isCountryFetchError,
    countryFetchError,
    userAddress,
    addressStructure,
    moreAddressDetails,
    isValidAddress,
    isFetchingAddressStructure,
    isAddressStructureFetchError,
    isValidAddressType,
    addressStructureError,
    activateButton,
  };

  const addressFunctions = {
    addressCountrySelectHandler,
    userAddressEntryHandler,
    moreAddressDetailsHandler,
    clearAddress,
    setMoreAddressDetails,
    addressTypeSelectHandler,
  };

  return {
    variableData,
    addressFunctions,
  };
};

export default useAddress;

/**
 *           <Address
            isRequired
            selectedCountry={userAddress.countryDetails}
            onAddressCountrySelect={addressCountrySelectHandler}
            addressStructure={addressStructure}
            onAddressEntry={userAddressEntryHandler}
            address={userAddress}
            onMoreAddressDetails={moreAddressDetailsHanlder}
            activateMoreAddressDetails={moreAddressDetails}
            validations={{
              isValidPostalCode: fieldInteracted.postalCode
                ? isValidPostalCode
                : true,
              isValidStreetAddress: fieldInteracted.streetAddress
                ? isValidStreetAddress
                : true,
              isValidDivision: fieldInteracted.division
                ? isValidDivision
                : true,
              isValidSubdivision: fieldInteracted.subDivision
                ? isValidSubdivision
                : true,
              isValidSettlement: fieldInteracted.settlement
                ? isValidSettlement
                : true,
            }}
            disabled={false}
            countries={countries}
            isLoading={isFetchingCountries}
            isError={isCountryFetchError}
            error={countryFetchError}
          />
 */
