import { FormEvent, useState } from 'react';
import Form from '../../../../UI/FormElements/Form/Form';
import Input from '../../../../UI/FormElements/Input/Input';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import classes from './AddNewUserForm.module.scss';
import Phone from '../../../../UI/Phone/Phone';
import usePhoneEntry from '../../../../../CustomHooks/usePhoneEntry';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import useFormEntry from '../../../../../CustomHooks/useFormEntry';
import {
  validateEmail,
  validateNameSurname,
  validatePassword,
} from '../../../../../utils/componentUtilFns';

import PasswordConditions from '../../../../UI/PasswordConditions/PasswordConditions';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { useSearchParams } from 'react-router-dom';
import PasswordStrengthTest from '../../../LoginRegister/SignUp/SignUpParts/PasswordStrengthTest/PasswordStrengthTest';
import { decodePattern } from '../../../../../utils/phoneUtilityFunctions/createPattern';
import { IUser } from '../../../../../tsTypes/types.model';
import { ICountryPhoneData } from '../../../../../tsTypes/types.components';
import { useAddNewUserToOrganizationUsersMutation } from '../../../../../features/userAdmin/userAdminSlice';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';

const validatePasswordEntry = (password: string) =>
  validatePassword(password, 6, 25);

const AddNewUserForm = () => {
  const [showConditionsBoard, setShowConditionsBoard] = useState(false);
  const [pageQuery] = useSearchParams();
  const contractId = pageQuery.get('contract');
  const [addNewOrganizationUser, { isLoading: isProcessingNewUser }] =
    useAddNewUserToOrganizationUsersMutation();
  const {
    phoneNumber,
    countryData,
    countryCodeSelectHandler,
    phoneEntryHandler,
    clearPhoneData,
  } = usePhoneEntry({ localStorageName: 'organization-user-phone' });

  const {
    value: name,
    isValid: isValidName,
    entryHandler: nameEntryHandler,
    clearEntry: clearNameEntry,
  } = useFormEntry(validateNameSurname, 'name', 'organization-user-name', true);
  const {
    value: surname,
    isValid: isValidSurname,
    entryHandler: surnameEntryHandler,
    clearEntry: clearSurnameEntry,
  } = useFormEntry(
    validateNameSurname,
    'surname',
    'organization-user-surname',
    true
  );
  const {
    value: email,
    isValid: isValidEmail,
    entryHandler: emailEntryHandler,
    clearEntry: clearEmailEntry,
  } = useFormEntry(validateEmail, 'email', 'organization-user-email');
  const {
    value: password,
    isValid: isValidPassword,
    entryHandler: passwordEntryHandler,
    clearEntry: clearPasswordEntry,
  } = useFormEntry(validatePasswordEntry);

  const validateConfirmPassword = (confirmPasswordEntry: string) =>
    validatePasswordEntry(confirmPasswordEntry) &&
    confirmPasswordEntry === password;

  const {
    value: confirmPassword,
    isValid: isValidConfirmPassword,
    entryHandler: confirmPasswordEntryHandler,
    clearEntry: clearConfirmPasswordEntry,
  } = useFormEntry(validateConfirmPassword);

  const showPasswordConditionsBoardHandler = () => {
    setShowConditionsBoard(true);
  };

  const hidePasswordConditionsBoardHandler = () => {
    setShowConditionsBoard(false);
  };

  const submitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (name.trim().length === 0 || surname.trim().length === 0) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage="Name and surname are required. Please review the fields and provide the user's name and surname."
            heading='Form Error'
          />
        );
      }

      if (email.trim().length === 0) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage='Email address is required. Please enter an email address for the user.'
            heading='Form Error'
          />
        );
      }

      if (phoneNumber.trim().length === 0) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Phone Number Error'
            singleMessage='Phone number is required. Please enter a valid phone number for the user.'
          />
        );
      }

      if (password.trim().length < 6 || confirmPassword.trim().length < 6) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Password Error'
            singleMessage='Password and confirm password are required. Please enter a valid password and confirm the password.'
          />
        );
      }

      // 1- Validate User Data
      if (!isValidPassword) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage='Kindly ensure your password meets the specified criteria. A prompt window will appear, providing guidance on the required password conditions. Craft your user password in accordance with these guidelines for a secure and effective login experience.'
            heading='Password Error'
          />
        );
      }

      if (!isValidConfirmPassword) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage='Please verify that your password and confirm password entries match. If they do not align, please ensure accurate input to proceed with the confirmation process.'
            heading='Password Mismatch'
          />
        );
      }

      if (!isValidEmail) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage='Please verify the entered user email address; it does not appear to be a valid email.'
            heading='Invalid Email'
          />
        );
      }

      if (!isValidName || !isValidSurname) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Name Error'
            singleMessage="Please verify the user's name and surname; either the name or surname does not seem to be valid."
          />
        );
      }

      if (
        !phoneNumber ||
        phoneNumber.trim().length < 5 ||
        isNaN(Number(decodePattern(phoneNumber)))
      ) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Phone Error'
            singleMessage="Please verify the user's phone number; a valid phone number is required."
          />
        );
      }

      let phoneData: ICountryPhoneData | null = null;

      if (phoneNumber && countryData) {
        phoneData = {
          code: countryData.countryCode,
          country: countryData.name,
          phoneNumber: decodePattern(phoneNumber),
        };
      }

      if (!contractId || !phoneData) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage="Apologies, we've encountered a problem processing user phone data. Please try again later. We appreciate your understanding."
            heading='Process Error'
          />
        );
      }

      const userData: Pick<
        IUser,
        | 'name'
        | 'surname'
        | 'email'
        | 'password'
        | 'confirmPassword'
        | 'userPhones'
      > = {
        name,
        surname,
        email,
        password,
        confirmPassword,
        userPhones: [phoneData],
      };

      //2- Submit Form Data to Backend and clear form for new user

      const res = await addNewOrganizationUser({
        user: userData,
        contractId,
      }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        clearNameEntry();
        clearSurnameEntry();
        clearEmailEntry();
        clearPasswordEntry();
        clearConfirmPasswordEntry();
        clearPhoneData();

        return toast.success(
          <ToastifyStyledMessage
            singleMessage='User added to organization users list successfully.'
            heading='Success'
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const isFakeButton =
    name.trim().length === 0 ||
    surname.trim().length === 0 ||
    email.trim().length < 4 ||
    phoneNumber.trim().length < 3 ||
    password.trim().length < 6 ||
    confirmPassword.trim().length < 6 ||
    !isValidPassword ||
    !isValidConfirmPassword ||
    !isValidEmail ||
    !isValidSurname ||
    !isValidName;

  return (
    <div className={classes.OrganizationFormContainer}>
      <HeadingBar heading='New Organization User Form' />
      <Form
        className={classes.OrganiationUserForm}
        onSubmit={submitFormHandler}
      >
        <Input
          name='name'
          label='Name'
          onChange={nameEntryHandler}
          value={name}
          isInvalid={!isValidName}
          isRequired
          disabled={isProcessingNewUser}
        />
        <Input
          name='surname'
          label='Surname'
          onChange={surnameEntryHandler}
          value={surname}
          isInvalid={!isValidSurname}
          isRequired
          disabled={isProcessingNewUser}
        />
        <Input
          name='email'
          label='Email Address'
          onChange={emailEntryHandler}
          value={email}
          isInvalid={!isValidEmail}
          isRequired
          disabled={isProcessingNewUser}
        />
        <Phone
          onPhoneEntry={phoneEntryHandler}
          phoneNumber={phoneNumber}
          onCodeSelect={countryCodeSelectHandler}
          countryData={countryData}
          disabled={isProcessingNewUser}
        />
        <Input
          name='password'
          label='Password'
          onChange={passwordEntryHandler}
          value={password}
          isInvalid={!isValidPassword}
          isRequired
          hasShowPasswordButton
          showInformationBoard={showConditionsBoard}
          onFocus={showPasswordConditionsBoardHandler}
          onBlur={hidePasswordConditionsBoardHandler}
          autoComplete='new-password'
          type='password'
          disabled={isProcessingNewUser}
        >
          <PasswordConditions />
        </Input>
        <Input
          name='confirm-password'
          label='Confirm Password'
          hasShowPasswordButton
          onChange={confirmPasswordEntryHandler}
          value={confirmPassword}
          isInvalid={!isValidConfirmPassword}
          isRequired
          type='password'
          disabled={isProcessingNewUser}
        />
        <div className={classes.PasswordStrengthContainer}>
          {!isProcessingNewUser ? (
            <PasswordStrengthTest password={password} />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SimpleLoading />
            </div>
          )}
        </div>
        <div></div>
        <ActionContainer setup={{ justifyContent: 'flex-end' }}>
          <StandardButton
            label='Add User'
            btnType='primary'
            type='submit'
            fakeButton={isProcessingNewUser || isFakeButton}
          />
        </ActionContainer>
      </Form>
    </div>
  );
};

export default AddNewUserForm;
