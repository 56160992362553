import { useEffect, useState, useRef, FormEvent, Fragment } from 'react';
import Form from '../../../../../../UI/FormElements/Form/Form';
import Input from '../../../../../../UI/FormElements/Input/Input';
import Address from '../../../../../../UI/Address/Address';

import {
  approveInvoiceDetails,
  nextPage,
  selectAllRegistrationFormValues,
  setAreasOfInterest,
  setSelectedOrganization,
  useAddNewOrganizationMutation,
  useSendNewOrganizationEmailVerificationMutation,
  useVerifyNewOrganizationEmailCodeMutation,
} from '../../../../../../../features/registration/registrationSlice';
import Phone from '../../../../../../UI/Phone/Phone';
import usePhoneEntry from '../../../../../../../CustomHooks/usePhoneEntry';
import ActionContainer from '../../../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../../../UI/FormElements/Button/Button';
import classes from './NewOrganizationForm.module.scss';
import {
  capitalizeAllWords,
  validateEmail,
  validateWebsite,
} from '../../../../../../../utils/componentUtilFns';
import SelectionForm from '../../SelectionForm/SelectionForm';
import {
  IAreaOfInterest,
  IOrganization,
} from '../../../../../../../tsTypes/types.components';
import useFormEntry from '../../../../../../../CustomHooks/useFormEntry';
import validateOrganizationName from '../../../../../../../utils/validateOrganizationName';
import AccountValidationCode from './AccountValidationCode/AccountValidationCode';
import addHttpsIfMissing from '../../../../../../../utils/addHttps';
import useAddress from '../../../../../../../CustomHooks/useAddress';
import PresentationWrapper from '../../../../../../UI/PresentationWrapper/PresentationWrapper';
import { catchError } from '../../../../../../../utils/fetches';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import CenterDiv from '../../../../../../UI/CenterDiv/CenterDiv';
import SimpleLoading from '../../../../../../UI/SimpleLoading/SimpleLoading';
import ApprovedBadge from '../../../../../../UI/ApprovedBadge/ApprovedBadge';
import AreasOfInterest from './AreasOfInterest/AreasOfInterest';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  CountryAlfa2Code,
  IPredefinedPolygon,
} from '../../../../../../../tsTypes/types.model';
import { decodePattern } from '../../../../../../../utils/phoneUtilityFunctions/createPattern';
import { AppDispatch } from '../../../../../../../app/store';
import { selectAllUIValues } from '../../../../../../../features/UI/uiSlice';
import { nanoid } from '@reduxjs/toolkit';
import validateVatByCountry from '../../../../../../../utils/validateVATByCountry';
import queryErrorCatch from '../../../../../../../utils/queryErrorCatch';

import FormSectionContainer from '../../../../../../UI/FormSectionContainer/FormSectionContainer';
import { useTranslation } from 'react-i18next';

interface IProps {
  predefinedPolygons: IPredefinedPolygon[];
  shouldSkip: boolean;
  setOrganizationId: (organizationId: string) => void;
}

const NewOrganizationForm = ({
  predefinedPolygons,
  shouldSkip,
  setOrganizationId,
}: IProps) => {
  const [selectedArea, setSelectedArea] = useState<IAreaOfInterest[]>([]);
  const [isValidCode, setIsValidCode] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const { t } = useTranslation();

  const {
    selectedOrganizationType,
    selectedDistrict,
    selectedProvince,
    selectedCountry,
    selectedPackage,
    selectedOrganization,
  } = useSelector(selectAllRegistrationFormValues);
  const { isPolygonListPresentationAtAreaOfInterest: buttonVisibility } =
    useSelector(selectAllUIValues);
  const dispatch = useDispatch<AppDispatch>();

  const isCommunity =
    selectedOrganizationType &&
    'title' in selectedOrganizationType &&
    selectedOrganizationType.title === 'Community'
      ? true
      : false;

  const isMunicipality =
    selectedOrganizationType &&
    'title' in selectedOrganizationType &&
    selectedOrganizationType.title === 'Municipality'
      ? true
      : false;

  const emailValidation = (email: string) => {
    if (isCommunity) {
      return validateEmail(email);
    } else {
      // return isValidCompanyEmail(email); In the future we will check company emails
      return validateEmail(email);
    }
  };

  const validateWeb = (webUrl: string) => {
    if (isCommunity || isMunicipality) {
      return true;
    } else {
      return validateWebsite(webUrl);
    }
  };

  const validateVAT = (vatNumber: string) => {
    const countryAlpha2Code =
      selectedCountry &&
      typeof selectedCountry === 'object' &&
      'alpha2Code' in selectedCountry &&
      selectedCountry.alpha2Code
        ? selectedCountry.alpha2Code
        : ('' as CountryAlfa2Code);

    return validateVatByCountry(vatNumber, countryAlpha2Code);
  };

  const {
    phoneNumber,
    countryData,
    countryCodeSelectHandler,
    phoneEntryHandler,
    clearPhoneData,
  } = usePhoneEntry({ localStorageName: 'organization-registration-phone' });

  const {
    value: newOrganizationName,
    entryHandler: organizationNameEntryHandler,
    isValid: isOrganizationNameValid,
    clearEntry: clearOrganizatioName,
  } = useFormEntry(validateOrganizationName, 'organization-name', 'new', true);

  const {
    value: organizationEmail,
    entryHandler: organizationEmailEntryHandler,
    isValid: isValidOrganizationEmail,
    clearEntry: clearEmailAddress,
  } = useFormEntry(emailValidation, 'organization-email', 'new');

  const {
    value: organizationWebsite,
    entryHandler: organizationWebsiteEntryHandler,
    isValid: isValidWebsite,
    setter: setOrganizationWebsite,
    clearEntry: clearWebsite,
  } = useFormEntry(validateWeb, 'organization-website', 'new');

  const {
    value: organizationVATNumber,
    entryHandler: organizationVATNumberEntryHandler,
    isValid: isValidVATNumber,
    clearEntry: clearVATNumber,
  } = useFormEntry(validateVAT, 'organization-vat', 'new', false, true);

  const { variableData, addressFunctions } = useAddress({
    userAddressLocalStorageName: 'organization-address',
    moreAddressDetailsLocalStorageName: 'more-orgenization-address-details',
    shouldSkip,
  });

  const {
    countries,
    isFetchingCountries,
    isCountryFetchError,
    countryFetchError,
    userAddress,
    addressStructure,
    moreAddressDetails,
    isValidAddress,
    isFetchingAddressStructure,
    isAddressStructureFetchError,
    addressStructureError,
    activateButton,
    isValidAddressType,
  } = variableData;

  const {
    addressCountrySelectHandler,
    userAddressEntryHandler,
    moreAddressDetailsHandler,
    clearAddress,
    addressTypeSelectHandler,
  } = addressFunctions;

  const [sendCodeToUser, { isLoading, isError, isSuccess, data, error }] =
    useSendNewOrganizationEmailVerificationMutation();

  const [
    verifyCode,
    {
      isLoading: isVerifying,
      isError: isVerificationError,
      isSuccess: isSuccessCode,
      error: verificationError,
      data: codeResult,
      reset: resetEmailCode,
    },
  ] = useVerifyNewOrganizationEmailCodeMutation();

  const [addOrganization, { isLoading: isAddingNewOrganization }] =
    useAddNewOrganizationMutation();

  const isSend = useRef(false);

  const isValidDivision = activateButton && activateButton.isValidDivision;
  const isValidPostalCode = activateButton && activateButton.isValidPostalCode;
  const isValidSettlement = activateButton && activateButton.isValidSettlement;
  const isValidStreetAddress =
    activateButton && activateButton.isValidStreetAddress;
  const isValidSubdivision =
    activateButton && activateButton.isValidSubdivision;

  const isValidCodeData =
    codeResult && 'isValidCode' in codeResult && codeResult.isValidCode;

  const verificationIdData =
    codeResult && 'verificationId' in codeResult && codeResult.verificationId
      ? codeResult.verificationId
      : null;

  useEffect(() => {
    if (isValidCodeData && verificationIdData) {
      setIsValidCode(isValidCodeData);
      setVerificationId(verificationIdData);
      localStorage.setItem(
        'organization-email-verification-id',
        verificationIdData
      );
    }
  }, [isValidCodeData, verificationIdData]);

  useEffect(() => {
    const emailVerificationId = localStorage.getItem(
      'organization-email-verification-id'
    );

    if (emailVerificationId) {
      setIsValidCode(true);
      setVerificationId(emailVerificationId);
      isSend.current = true;
    }
  }, []);

  useEffect(() => {
    const storedSelectedArea = localStorage.getItem(
      'selected-area-of-interest'
    );

    if (storedSelectedArea) {
      const parsedData = JSON.parse(storedSelectedArea);
      setSelectedArea(parsedData);
    }
  }, []);

  useEffect(() => {
    if (
      organizationWebsite &&
      organizationWebsite.length >= 5 &&
      isValidWebsite
    ) {
      const updatedWebsiteAddress = addHttpsIfMissing(
        organizationWebsite,
        isValidWebsite
      );
      setOrganizationWebsite(updatedWebsiteAddress);
    }
    // eslint-disable-next-line
  }, [organizationWebsite, isValidWebsite]);

  const phoneLength =
    countryData &&
    typeof countryData === 'object' &&
    'NSN' in countryData &&
    countryData.NSN
      ? countryData.NSN
      : 4;

  useEffect(() => {
    if (
      organizationEmail &&
      organizationEmail.length >= 5 &&
      isValidOrganizationEmail &&
      newOrganizationName &&
      isOrganizationNameValid &&
      isValidWebsite &&
      phoneNumber &&
      phoneNumber.length >= phoneLength &&
      isValidDivision &&
      isValidPostalCode &&
      isValidSettlement &&
      isValidStreetAddress &&
      isValidSubdivision &&
      !isSend.current
    ) {
      sendCodeToUser({
        email: organizationEmail,
        organizationName: newOrganizationName,
        isCommunity,
      });
      isSend.current = true;
    }
    // eslint-disable-next-line
  }, [
    organizationEmail,
    isValidOrganizationEmail,
    newOrganizationName,
    isOrganizationNameValid,
    organizationWebsite,
    isValidWebsite,
    phoneNumber,
    selectedArea,
    isValidDivision,
    isValidPostalCode,
    isValidSettlement,
    isValidStreetAddress,
    isValidSubdivision,
  ]);
  // const areDomainsMatch = isCommunity
  //   ? true
  //   : companyEmailWebMatch(organizationEmail, organizationWebsite);
  const areDomainsMatch = true;

  useEffect(() => {
    if (isError) {
      const messageData = catchError(error);
      const message = messageData.message;

      toast.error(
        <ToastifyStyledMessage
          singleMessage={message}
          heading={t('auth:Code_Send_Error')}
        />
      );
    }

    if (isVerificationError) {
      const messageData = catchError(verificationError);
      const message = messageData.message;

      toast.error(
        <ToastifyStyledMessage
          singleMessage={message}
          heading={t('auth:Code_Send_Error')}
        />
      );
    }
    // eslint-disable-next-line
  }, [isError, isVerificationError]);

  /**
   *
   * @TODO : Add area of the selected district, we will calculate the total price from the selected total area
   * package prices should be revised according to that,
   * Package price :
   * PREMIUM : €1.45 per sq km + €95 Euros => if user selects 800 sq km => Total Price will be €1255 Euros + VAT (5%)
   * BASIC : €1.48 per sq km + €125 Euros => if user selects 800 sq km => Total Price will be €1309 Euros + VAT (5%)
   *
   *
   */
  const areaOfInterestSelectHandler = (areaOfInterestData: {
    district: string;
    mid: string;
    area: number;
    areaUnit: string;
    polygonName: string;
  }) => {
    const { district, mid, area, areaUnit, polygonName } = areaOfInterestData;
    let country =
      selectedCountry && 'name' in selectedCountry && selectedCountry.name
        ? selectedCountry.name
        : '';
    let province =
      selectedProvince && 'name' in selectedProvince && selectedProvince.name
        ? selectedProvince.name
        : '';

    if (isCommunity || isMunicipality) {
      setSelectedArea([
        { district, mid, province, country, area, areaUnit, polygonName },
      ] as IAreaOfInterest[]);

      localStorage.setItem(
        'selected-area-of-interest',
        JSON.stringify([
          { district, mid, province, country, area, areaUnit, polygonName },
        ])
      );
    } else {
      setSelectedArea((prevState) => {
        const mids = prevState.map((item) => item.mid);
        let updatedData: IAreaOfInterest[] = [];

        if (mids.includes(mid)) {
          updatedData = prevState.filter(
            (item) => item.mid !== mid
          ) as IAreaOfInterest[];
        } else {
          updatedData = [
            ...prevState,
            { district, mid, province, country, area, areaUnit, polygonName },
          ] as IAreaOfInterest[];
        }

        localStorage.setItem(
          'selected-area-of-interest',
          JSON.stringify(updatedData)
        );
        return updatedData;
      });
    }

    dispatch(
      setAreasOfInterest({
        district,
        mid,
        province,
        country,
        area,
        areaUnit,
        polygonName,
      })
    );
  };

  const clearForm = () => {
    localStorage.removeItem('organization-registration-phone-country-data');
    localStorage.removeItem('organization-address');
    localStorage.removeItem('periopsis-new-organization-name');
    localStorage.removeItem('periopsis-new-organization-email');
    localStorage.removeItem('periopsis-new-organization-website');
    localStorage.removeItem('organization-registration-phone');
    localStorage.removeItem('new-organization-form');
    localStorage.removeItem('selected-area-of-interest');
    localStorage.removeItem('selected-filter-letter');
    localStorage.removeItem('more-orgenization-address-details');
    clearAddress();
    clearEmailAddress();
    clearOrganizatioName();
    clearWebsite();
    clearPhoneData();
    setSelectedArea([]);
    setIsValidCode(false);
    setVerificationId('');
    clearVATNumber();
    isSend.current = false;
    resetEmailCode();
  };

  const resendVerificationCodeHandler = async () => {
    await sendCodeToUser({
      email: organizationEmail,
      organizationName: newOrganizationName,
      isCommunity,
    }).unwrap();
  };

  const verifyCodeHandler = async (code: string) => {
    verifyCode({ email: organizationEmail, code });
  };

  const clearFormHandler = () => {
    confirmAlert({
      title: t('auth:Are_you_sure'),
      message: t('auth:delete_organization_data'),
      buttons: [
        {
          label: t('auth:Clear'),
          onClick: clearForm,
          style: {
            backgroundColor: 'rgb(219, 25, 25)',
            fontWeight: '600',
            textTransform: 'uppercase',
            letterSpacing: '0.25rem',
            padding: '0.7rem 0.95rem',
          },
        },
        {
          label: t('auth:Cancel'),
          onClick: () => {},
          style: {
            fontWeight: '600',
            textTransform: 'uppercase',
            letterSpacing: '0.25rem',
            padding: '0.7rem 0.95rem',
          },
        },
      ],
    });
  };

  const isAllValid =
    isOrganizationNameValid &&
    isValidOrganizationEmail &&
    isValidWebsite &&
    isValidDivision &&
    isValidPostalCode &&
    isValidSettlement &&
    isValidStreetAddress &&
    isValidSubdivision &&
    selectedArea &&
    selectedArea.length > 0 &&
    isValidVATNumber &&
    isValidAddressType &&
    areDomainsMatch;

  const addNewOrganizationFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (!isAllValid && !isValidCode) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('auth:all_fields_message')}
            heading={t('auth:Form_Error')}
          />
        );
      }

      if (
        !newOrganizationName ||
        !selectedOrganizationType ||
        !countryData ||
        !selectedDistrict ||
        !selectedPackage
      ) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('auth:Form_Error')}
            singleMessage={t('auth:incomplete_from_message')}
          />
        );
      }

      //We will send this to the new organization controller and we will get all details as response for the complete registration
      //in response, !! at registration user will use an email address, from the organization id which we will get at response
      //at the registration process, we will check if user has the same email address and hence we will not send a confirmation
      //email to user and we will automatically make account confirmed for such users. we will send verification id at user registration
      //and
      /**
       * we will check
       * 1- organization email by selectedOrganizationId,
       * 2- then we will compare user email and organization email,
       * 3- we will check verification data by using verification id, if it is exist and verified, we will not send any confirmation email
       * to user and we will assign user.accountVerified = true
       */

      const organization: IOrganization = {
        organizationName: newOrganizationName,
        address: userAddress,
        email: organizationEmail,
        web: organizationWebsite,
        phone: decodePattern(phoneNumber),
        phoneCountry: {
          countryCode: countryData.countryCode,
          name: countryData.name,
          code: countryData.code,
          NSN: countryData.NSN,
        },
        organizationType: selectedOrganizationType.title,
        vatNumber: organizationVATNumber,
        // areasOfInterest: selectedArea,
      };

      const requestData = {
        organization,
        verificationId,
        selectedPackageId: selectedPackage._id,
      };

      const response = await addOrganization(requestData).unwrap();

      if (
        response &&
        'isSuccess' in response &&
        response.isSuccess &&
        'data' in response &&
        typeof response.data === 'object' &&
        Object.keys(response.data).length > 0 &&
        'organization' in response.data &&
        response.data.organization &&
        typeof response.data.organization === 'object'
      ) {
        const selectedOrg: Pick<
          IOrganization,
          'id' | 'organizationName' | 'organizationType' | 'email'
        > = {
          id: response.data.organization.id,
          organizationName: response.data.organization.organizationName,
          organizationType: response.data.organization.organizationType,
          // areasOfInterest: response.data.organization.areasOfInterest,
          email: response.data.organization.organizationEmail,
        };

        localStorage.setItem(
          'selected-organization',
          JSON.stringify(selectedOrg)
        );
        clearForm();
        setOrganizationId(response.data.organization.id);
        toast.success(
          <ToastifyStyledMessage
            singleMessage={t('auth:select_success_message')}
            heading={t('auth:next_page')}
          />,
          { autoClose: 1000 }
        );
        localStorage.removeItem('invoice-details-approved');

        batch(() => {
          dispatch(setSelectedOrganization(selectedOrg));
          dispatch(approveInvoiceDetails(false));
          const timer = setTimeout(() => {
            dispatch(nextPage());
            toast.dismiss();
            clearTimeout(timer);
          }, 1600);
        });
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const individualRegistrationOrganizationHandler = () => {
    if (
      selectedOrganization &&
      selectedArea &&
      Array.isArray(selectedArea) &&
      selectedArea.length > 0
    ) {
      toast.success(
        <ToastifyStyledMessage
          singleMessage={t('auth:select_success_message')}
          heading={t('auth:next_page')}
        />,
        { autoClose: 1000 }
      );
      localStorage.removeItem('invoice-details-approved');

      batch(() => {
        dispatch(approveInvoiceDetails(false));
        const timer = setTimeout(() => {
          dispatch(nextPage());
          toast.dismiss();
          clearTimeout(timer);
        }, 1600);
      });
    } else if (
      selectedOrganizationType &&
      'title' in selectedOrganizationType &&
      selectedOrganizationType.title &&
      selectedArea &&
      Array.isArray(selectedArea) &&
      selectedArea.length > 0
    ) {
      const dummyOrganizationId = `individual-${nanoid(
        15
      )}-${new Date().getTime()}`;

      const selectedOrg: Pick<
        IOrganization,
        'id' | 'organizationName' | 'organizationType' | 'email'
      > = {
        id: dummyOrganizationId,
        organizationName: `Individual-${nanoid(12)}`,
        organizationType: selectedOrganizationType.title,
        email: '',
      };

      toast.success(
        <ToastifyStyledMessage
          singleMessage={t('auth:select_success_message')}
          heading={t('auth:next_page')}
        />,
        { autoClose: 1000 }
      );
      localStorage.removeItem('invoice-details-approved');

      localStorage.setItem(
        'selected-organization',
        JSON.stringify(selectedOrg)
      );

      batch(() => {
        dispatch(setSelectedOrganization(selectedOrg));
        dispatch(approveInvoiceDetails(false));
        const timer = setTimeout(() => {
          dispatch(nextPage());
          toast.dismiss();
          clearTimeout(timer);
        }, 1600);
      });
    }
  };

  if (
    selectedOrganizationType &&
    'title' in selectedOrganizationType &&
    selectedOrganizationType.title === 'Individual'
  ) {
    return (
      <SelectionForm showCheckMark={false}>
        <Form className={classes.RegistrationForm}>
          <div className={classes.DropdownsSection}>
            <AreasOfInterest
              predefinedPolygons={predefinedPolygons}
              onSelect={areaOfInterestSelectHandler}
              selected={selectedArea}
              hideMapHeading
            />

            <ActionContainer
              className={`${classes.FormSubmitButtons} ${classes.Continue}`}
            >
              {selectedArea.length > 0 ? (
                <Button onClick={individualRegistrationOrganizationHandler}>
                  {t('auth:Continue')}
                </Button>
              ) : null}
            </ActionContainer>
          </div>
        </Form>
      </SelectionForm>
    );
  }

  return (
    <SelectionForm showCheckMark={false}>
      <Form
        className={classes.RegistrationForm}
        onSubmit={addNewOrganizationFormSubmit}
      >
        {!verificationId || !isValidCode ? (
          <Fragment>
            {!areDomainsMatch ? (
              <div className={classes.DomainError}>
                <p>{t('auth:email_website_domain_error')}</p>
              </div>
            ) : null}
            <FormSectionContainer
              heading={t('auth:Organization_Details')}
              description={t('auth:organization_form_desc')}
            >
              <Input
                name='organization-name'
                label={t('auth:Organization_Name')}
                type='text'
                value={newOrganizationName}
                onChange={organizationNameEntryHandler}
                isInvalid={!isOrganizationNameValid}
                isRequired
                title={t('auth:Organization_Name_title')}
              />
              <Input
                name='organization-email'
                label={t('auth:Organization_Email_Address')}
                type='email'
                value={organizationEmail}
                onChange={organizationEmailEntryHandler}
                isInvalid={!isValidOrganizationEmail}
                isRequired
                title={t('auth:Organization_Email_Address_Title')}
              />
              <Input
                name='organization-web'
                label={t('auth:Organization_Website')}
                type='url'
                value={organizationWebsite}
                onChange={organizationWebsiteEntryHandler}
                isInvalid={!isValidWebsite}
                // isRequired={!isCommunity}
                title={t('auth:Organization_Website_title')}
              />
              <Input
                name='organization-vat'
                label={t('auth:Organization_VAT')}
                type='text'
                value={organizationVATNumber}
                onChange={organizationVATNumberEntryHandler}
                isInvalid={!isValidVATNumber}
                isRequired
                title={t('auth:Organization_VAT_title')}
              />
            </FormSectionContainer>

            <div className={classes.DropdownsSection}>
              <PresentationWrapper
                isLoading={isFetchingAddressStructure}
                isError={isAddressStructureFetchError}
                error={catchError(addressStructureError)}
                message={t('auth:address_structure_fetch_error')}
              >
                <Address
                  isRequired
                  selectedCountry={userAddress.countryDetails}
                  onAddressCountrySelect={addressCountrySelectHandler}
                  addressStructure={addressStructure}
                  onAddressEntry={userAddressEntryHandler}
                  address={userAddress}
                  onMoreAddressDetails={moreAddressDetailsHandler}
                  onAddressTypeSelect={addressTypeSelectHandler}
                  activateMoreAddressDetails={moreAddressDetails}
                  validations={isValidAddress}
                  disabled={false}
                  countries={countries}
                  isLoading={isFetchingCountries}
                  isError={isCountryFetchError}
                  error={countryFetchError}
                />
              </PresentationWrapper>
              <Phone
                onPhoneEntry={phoneEntryHandler}
                phoneNumber={phoneNumber}
                onCodeSelect={countryCodeSelectHandler}
                countryData={countryData}
                fetchSkipCondition={false}
                disabled={false}
              />
              {isSuccess &&
              data &&
              data.isEmailSend &&
              !isSuccessCode &&
              !isVerifying ? (
                <AccountValidationCode
                  onResend={resendVerificationCodeHandler}
                  onVerify={verifyCodeHandler}
                  isValidCode={isValidCode || false}
                  isLoading={isLoading}
                  isError={isError}
                />
              ) : null}

              {isSuccess && isVerifying && !isSuccessCode ? (
                <CenterDiv>
                  <SimpleLoading />
                </CenterDiv>
              ) : null}

              {isSuccessCode && isValidCode ? (
                <div className={classes.BadgeSection}>
                  <ApprovedBadge text='Email Verified' />
                </div>
              ) : null}
            </div>
          </Fragment>
        ) : null}
        {verificationId && isValidCode ? (
          <AreasOfInterest
            predefinedPolygons={predefinedPolygons}
            onSelect={areaOfInterestSelectHandler}
            selected={selectedArea}
            disabled={isAddingNewOrganization}
          />
        ) : null}

        {!isAddingNewOrganization && !buttonVisibility ? (
          <ActionContainer className={classes.FormSubmitButtons}>
            <Button
              onClick={clearFormHandler}
              isFake={
                (!isOrganizationNameValid && !isValidOrganizationEmail) ||
                isAddingNewOrganization
              }
              type='button'
              title='This action will erase all organizational data you have entered in this section of the registration form.'
            >
              {t('auth:Clear_Organization_Form')}
            </Button>
            {verificationId && isValidCode ? (
              <Button
                btnClass={classes.ActionButton}
                isFake={!isAllValid}
                style={{
                  width: '100%',
                }}
                type='submit'
              >
                {capitalizeAllWords(t('auth:Add_Organization'))}
              </Button>
            ) : null}
          </ActionContainer>
        ) : buttonVisibility ? null : (
          <CenterDiv>
            <SimpleLoading />
          </CenterDiv>
        )}
      </Form>
    </SelectionForm>
  );
};

export default NewOrganizationForm;
