import { useState, useEffect, ChangeEvent } from 'react';
import { CountryAlfa2Code, ICountryPhone } from '../tsTypes/types.model';
import isValidNumber from '../utils/phoneUtilityFunctions/preventCharacterEntry';
import * as DOMPurify from 'dompurify';

interface IProps {
  localStorageName?: string;
}

const usePhoneEntry = ({ localStorageName }: IProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryData, setCountryData] = useState<ICountryPhone | null>(null);

  useEffect(() => {
    const phoneNumber = localStorage.getItem(`${localStorageName}`);
    const countryData = localStorage.getItem(
      `${localStorageName}-country-data`
    );

    if (phoneNumber) {
      setPhoneNumber(DOMPurify.sanitize(phoneNumber));
    }

    if (countryData) {
      const parsedData: ICountryPhone = JSON.parse(countryData);
      let pruifiedData: ICountryPhone = {
        code: DOMPurify.sanitize(parsedData.code) as CountryAlfa2Code,
        name: DOMPurify.sanitize(parsedData.name),
        countryCode: DOMPurify.sanitize(parsedData.countryCode),
        NSN: Number(DOMPurify.sanitize(parsedData.NSN.toString())),
      };

      setCountryData(pruifiedData);
    }
  }, [localStorageName]);

  const phoneEntryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const phoneNum = e.target.value;

    const purfiedPhoneNumber = DOMPurify.sanitize(phoneNum);

    const isNumber = isValidNumber(purfiedPhoneNumber);

    if (!isNumber) {
      return;
    }

    setPhoneNumber(purfiedPhoneNumber);
    localStorage.setItem(`${localStorageName}`, purfiedPhoneNumber);
  };

  const countryCodeSelectHandler = (codeData: ICountryPhone) => {
    let pruifiedData: ICountryPhone = {
      code: DOMPurify.sanitize(codeData.code) as CountryAlfa2Code,
      name: DOMPurify.sanitize(codeData.name),
      countryCode: DOMPurify.sanitize(codeData.countryCode),
      NSN: Number(DOMPurify.sanitize(codeData.NSN.toString())),
    };

    setCountryData(pruifiedData);

    localStorage.setItem(
      `${localStorageName}-country-data`,
      JSON.stringify(pruifiedData)
    );

    if (phoneNumber) {
      setPhoneNumber('');
      localStorage.removeItem(`${localStorageName}`);
    }
  };

  const clearPhoneData = () => {
    setPhoneNumber('');
    if (localStorageName) {
      localStorage.removeItem(localStorageName);
      localStorage.removeItem('');
    }
  };

  return {
    phoneNumber,
    countryData,
    phoneEntryHandler,
    countryCodeSelectHandler,
    clearPhoneData,
    setCountryData,
  };
};

export default usePhoneEntry;
